import axios from 'axios'
import { Message } from 'element-ui'
import { Toast } from 'vant'

//  axios.create()创建一个新的axios对象 满足于创建不同的baseURL
import store from '../store'
const request = axios.create({
  baseURL: '/api',
  timeout: 60000 //  响应时间  超过超时
})

// 添加请求拦截器
// request.interceptors.request.use(
//     (config) => {
//         // nprogress.start()
//         if (sessionStorage.getItem("token")) {
//             config.headers.Authorization = sessionStorage.getItem("token")
//         }
//         return config
//     },
//     (error) => {
//         return Promise.reject(error)
//     }
// )

// 添加响应拦截器
request.interceptors.response.use(
  function ({ data }) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (data.code !== 0) {
      switch (data.code) {
        case 401:
        case 402:
          store.commit('SET_USER_INFO', {})
          window.location.href = '/ '
          return Message.error('登录失效，请重新登录')

        default:
          break
      }
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        Toast({
          message: data.msg,
          position: 'top'
        })
      } else {
        Message.info(data.msg)
      }
      return Promise.reject(data)
    }
    return data
  },
  function ({ response }) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    switch (response.status) {
      case 500:
        return Message.error('服务器异常')

      default:
        if (
          window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          console.log(1111)
          Toast({
            message: response.data.msg,
            position: 'top'
          })
        } else {
          Message.error(response.data.msg)
        }
        break
    }
    return Promise.reject(response)
  }
)
export default request
