<template>
  <div>
    <!-- 一级路由 -->
    <router-view></router-view>
    <!-- 一级路由 -->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  methods: {}
}
</script>

<style scoped lang="less"></style>
