// 配置全局访问接口地址
let commonUrl

if (process.env.NODE_ENV === 'development') {
  commonUrl = 'https://8k577e8141.vicp.fun' // 开发环境
} else if (window.location.href.includes('121.41')) {
  commonUrl = 'https://8k577e8141.vicp.fun' // 测试环境，"121.41"根据实际情况而定
} else {
  commonUrl = 'https://hzzcxlt.xueqingtech.com'
}

const baseUrl = {
  commonUrl
}

export default baseUrl
